<template>
	<v-container class="pa-0" fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" @click.stop="getFiles" small color="primary">
				<v-icon left>mdi-reload</v-icon>
				Recargar
			</v-btn>
		</div>
		<v-data-table :items="documentos" :headers="documentosHeader">
			<template v-slot:item.acciones="{ item }">
				<v-btn
					@click="verDocumento(item.FileName, item.IdDocumento, true)"
					dark
					text
					color="secondary"
					:loading="downloadingFile"
				>
					<v-icon left>mdi-download</v-icon>Descargar
				</v-btn>
				<v-btn
					@click="verDocumento(item.FileName, item.IdDocumento, false)"
					dark
					text
					color="secondary"
					:loading="downloadingFile"
				>
					<v-icon left>mdi-eye</v-icon>Ver
				</v-btn>
				<v-btn
					color="error"
					text
					small
					class="ml-1"
					v-if="$root.acceso('DEV') || ($root.acceso('CREAR_CONTRATO') )"
					@click="eliminarDocumento(item.FileName, item.IdDocumento)"
					><v-icon left>mdi-delete</v-icon>Borrar</v-btn
				>
			</template>
			<template v-slot:item.FileName="{ item }">
				<v-chip
					class="text-button"
					color="transparent"
					text-color="secondary"
					outlined
					label
					>{{ item.FileName }}</v-chip
				>
			</template>
			<template v-slot:item.FechaCreacion="{ item }">
				{{ parseDate(item.FechaCreacion) }}
			</template>
			<template v-slot:item.FechaModificacion="{ item }">
				{{ parseDate(item.FechaModificacion) }}
			</template>
			<template v-slot:item.FileSize="{ item }">
				{{ humanFileSize(item.FileSize) }}
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import { parseDate, processToken, downloadFile } from "@/utils/index.js";
import extensions from "@/utils/mimeExtensions.js";

export default {
	props: {
		codigoContrato: Number | String,
	},
	data() {
		return {
			documentosHeader: [
				{ text: "Nombre", value: "FileName" },
				{ text: "Tamaño", value: "FileSize" },
				{ text: "Fecha Creacion", value: "FechaCreacion" },
				{ text: "Fecha Modificacion", value: "FechaModificacion" },
				{ text: "Acciones", value: "acciones", sortable: false },
			],
			documentos: [],
			downloadingFile: null,
		};
	},
	methods: {
		parseDate,
		humanFileSize(bytes, dp = 1) {
			const thresh = 1000;

			if (Math.abs(bytes) < thresh) {
				return bytes + " B";
			}

			const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (
				Math.round(Math.abs(bytes) * r) / r >= thresh &&
				u < units.length - 1
			);

			return bytes.toFixed(dp) + " " + units[u];
		},
		verDocumento(doc, id, descargar = false) {
			this.downloadingFile = true;
			let str = this.$store.getters.getDatosEmpresa;

			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			doc = doc.match(/(.*)\.(.*)/);
			var filename = doc[1];
			var extension = doc[2].toLowerCase();
			axios({
				method: "GET",
				url: `${process.env.VUE_APP_API_URL}/Documento/Test/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${id}/Extension/${extension}`,
				responseType: "blob",
				headers: {
					Authorization: this.$store.getters.getToken,
				},
			})
				.then(({ data }) => {
					let file = new File([data], `${filename}.${extension}`, {
						type: extensions[extension],
					});
					downloadFile(file, !descargar);
					this.downloadingFile = false;
				})
				.catch((e) => {
					this.$root.$emit("snack", e);
					// this.$root.$emit("snack", "Ha ocurrido un error");
					this.downloadingFile = false;
				});
		},
		eliminarDocumento(doc, id) {
			if (!confirm("¿Estás seguro de que quieres eliminar este archivo?"))
				return;
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				url: `${process.env.VUE_APP_API_URL}/Documento/BorrarDocumento`,
				method: "POST",
				data: {
					idDocumento: id,
					IdUsuario,
					IdEmpresa,
				},
				headers: {
					Authorization: this.$store.getters.getToken,
				},
			})
				.then((res) => {
					this.$root.$emit("snack", "Documento eliminado con éxito");
					this.getFiles();
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
		async getFiles() {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			let { data: documentos } = await axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/Documento/GetDatosDocumento/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Contrato/${Math.abs(this.codigoContrato)}`,
				headers: {
					Authorization: this.$store.getters.getToken,
				},
			});
			this.documentos = processToken(documentos).iss.filter(
				(item) => !!item.FileName
			);
			this.loading = false;
		},
	},
	mounted() {
		if (!isNaN(Number(this.codigoContrato))) this.getFiles();
	},
};
</script>

<style>
</style>