var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"small":"","color":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.getFiles($event)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v(" Recargar ")],1)],1),_c('v-data-table',{attrs:{"items":_vm.documentos,"headers":_vm.documentosHeader},scopedSlots:_vm._u([{key:"item.acciones",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"dark":"","text":"","color":"secondary","loading":_vm.downloadingFile},on:{"click":function($event){return _vm.verDocumento(item.FileName, item.IdDocumento, true)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Descargar ")],1),_c('v-btn',{attrs:{"dark":"","text":"","color":"secondary","loading":_vm.downloadingFile},on:{"click":function($event){return _vm.verDocumento(item.FileName, item.IdDocumento, false)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye")]),_vm._v("Ver ")],1),(_vm.$root.acceso('DEV') || (_vm.$root.acceso('CREAR_CONTRATO') ))?_c('v-btn',{staticClass:"ml-1",attrs:{"color":"error","text":"","small":""},on:{"click":function($event){return _vm.eliminarDocumento(item.FileName, item.IdDocumento)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v("Borrar")],1):_vm._e()]}},{key:"item.FileName",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"text-button",attrs:{"color":"transparent","text-color":"secondary","outlined":"","label":""}},[_vm._v(_vm._s(item.FileName))])]}},{key:"item.FechaCreacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.FechaCreacion))+" ")]}},{key:"item.FechaModificacion",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.parseDate(item.FechaModificacion))+" ")]}},{key:"item.FileSize",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.humanFileSize(item.FileSize))+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }